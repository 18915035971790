<template>
  <div class="header">
    <div class="header__logo">
      <img :alt="$t('img-alt.hotmart-logo')" src="../../static/images/hotmart-logo.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotHeader',
  props: {
    className: String,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_shared';

.header {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 14px;
  background: white;
}

.header__logo {
  width: 100%;
  max-width: 7rem;
  min-width: 7rem;
}
</style>
