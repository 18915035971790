import { library } from '@fortawesome/fontawesome-svg-core/index'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons/faArrowUpFromBracket'
import { faExclamationTriangle as faExclamationTriangleLight } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faTimesCircle as faTimesCircleLight } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
library.add([
  faExclamationTriangle,
  faPlusCircle,
  faQuestionCircle,
  faClock,
  faTimesCircle,
  faTimesCircleLight,
  faCheckCircle,
  faInfoCircle,
  faArrowRight,
  faExclamationTriangleLight,
  faArrowLeft,
  faArrowUpFromBracket,
  faPaperclip,
])
