import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    stepUser: {
      validateData: false,
      token: false,
      product: false,
      receivingAccounts: false,
      refundReason: false,
      refundFeedback: false,
      email: false,
      productAccess: false,
    },
    purchaseData: {},
    multiplePayments: false,
    findPurchase: false,
    resendEmailSent: false,
  },
  mutations: {
    SET_CHECK_STEP(state, payload) {
      if (payload === 'token' && state.stepUser.token) {
        state.stepUser = {
          validateData: false,
          token: false,
          product: false,
          receivingAccounts: false,
          refundReason: false,
          refundFeedback: false,
          email: false,
        }
        return
      }

      if (payload === 'product' && state.stepUser.product) {
        state.stepUser.product = false
        state.stepUser.receivingAccounts = false
        state.stepUser.refundReason = false
        state.stepUser.refundFeedback = false
        state.stepUser.productAccess = false
        return
      }
      if (payload === 'receivingAccounts' && state.stepUser.receivingAccounts) {
        state.stepUser.receivingAccounts = false
        state.stepUser.refundReason = false
        state.stepUser.refundFeedback = false
        return
      }
      if (
        (payload === 'refundReason' && state.stepUser.refundReason) ||
        (payload === 'refundFeedback' && state.stepUser.refundFeedback)
      ) {
        state.stepUser = {
          validateData: false,
          token: false,
          product: false,
          receivingAccounts: false,
          refundReason: false,
          refundFeedback: false,
          email: false,
          productAccess: false,
        }
        return
      }

      state.stepUser[payload] = !state.stepUser[payload]
    },
    SET_PURCHASE_DATA(state, payload) {
      state.purchaseData = {
        ...state.purchaseData,
        ...payload,
      }
    },
    SET_RESET_CHECK_STEP(state) {
      state.stepUser = {
        validateData: false,
        token: false,
        product: false,
        receivingAccounts: false,
        refundReason: false,
        refundFeedback: false,
        email: false,
        productAccess: false,
      }
    },
    SET_BANK_PURCHASE_DATA(state, payload) {
      state.purchaseData.bank = {
        ...payload,
      }
    },
    SET_MULTIPLE_PAYMENTS(state, payload) {
      state.multiplePayments = payload
    },
    SET_NEW_FLOW(state, payload) {
      state.findPurchase = payload
    },
    SET_RESEND_EMAIL_SENT(state, payload) {
      state.resendEmailSent = payload
    },
  },
  actions: {
    checkStepUser({ commit }, payload) {
      return commit('SET_CHECK_STEP', payload)
    },
    resetStepUser({ commit }, payload) {
      return commit('SET_RESET_CHECK_STEP', payload)
    },
    purchaseData({ commit }, payload) {
      return commit('SET_PURCHASE_DATA', payload)
    },
    errorSavingBankData({ commit }, payload) {
      return commit('SET_PURCHASE_DATA', payload)
    },
    bankPurchaseData({ commit }, payload) {
      return commit('SET_BANK_PURCHASE_DATA', payload)
    },
    multiplePayments({ commit }, payload) {
      return commit('SET_MULTIPLE_PAYMENTS', payload)
    },
    findPurchase({ commit }, payload) {
      return commit('SET_NEW_FLOW', payload)
    },
    resendEmailSent({ commit }, payload) {
      return commit('SET_RESEND_EMAIL_SENT', payload)
    },
  },
  getters: {
    getPurchaseData: state => state.purchaseData,
    getFindPurchase: state => state.findPurchase,
  },
})
