<template>
  <hot-toast></hot-toast>
</template>
<script>
import '@hotmart/cosmos-web-component/dist/icon'
import '@hotmart/cosmos/dist/alert'
import '@hotmart/cosmos/dist/toast'

export default {
  name: 'VueToast',
}
</script>

<style lang="scss">
@import '~@hotmart/cosmos/dist/alert/variations/success';
@import '~@hotmart/cosmos/dist/alert/variations/danger';
@import '~@hotmart/cosmos/dist/alert/variations/warning';
</style>
