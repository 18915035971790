import Toast from './Toast.vue'

var ToastPlugin = {
  install(Vue) {
    // Create plugin's root Vue instance
    const root = new Vue({
      render: createElement => createElement(Toast),
    })

    // Mount root Vue instance on new div element added to body
    root.$mount(document.body.appendChild(document.createElement('div')))
    const hotToast = document.querySelector('hot-toast')

    Object.defineProperty(Vue.prototype, '$hotToast', {
      get() {
        return hotToast
      },
    })
  },
}

export default ToastPlugin
