<template>
  <div id="app">
    <Header />
    <div :class="['containerApp', { '_w-half': this.registeredAccount }]">
      <h1 v-if="renderTitles" class="_d-none _d-md-block _text-2 _text-md-8 title">
        {{ $t(`${titlesPage}`) }}
      </h1>
      <h2
        v-if="renderTitles && !this.registeredAccount"
        class="_d-none _d-md-block _text-3 _text-gray-600 _font-weight-light"
      >
        {{
          this.refundTracking
            ? $t(subTitlesPage)
            : $t(subTitlesPage, {
                typeMode: $t(`${renderSubTitle}`),
              })
        }}
      </h2>
      <div class="_mt-2 _mt-md-12 _pb-5">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { paymentMode } from '@/common/constants/paymentType'
import '@/common/polyfill/crypto'
import { mapGetters } from 'vuex'
import Header from './components/Header'

export default {
  name: 'App',
  components: {
    Header,
  },
  data: function () {
    return {
      titlesPage: '',
      subTitlesPage: '',
      registeredAccount: false,
      refundTracking: false,
      physicalProduct: false,
    }
  },
  beforeUpdate: function () {
    this.refundTracking = ['refundTracking'].includes(this.$route.name)
    this.registeredAccount = ['Brazil', 'International'].includes(this.$route.name)
    this.physicalProduct = this.getPurchaseData.isPhysicalProduct

    this.titlesPage = 'general.refund-request'
    this.subTitlesPage = 'general.refund-information'

    if (this.refundTracking) {
      this.titlesPage = 'refund-tracking.title'
      this.subTitlesPage = 'general.tracking-information'
      return
    }

    if (this.registeredAccount) {
      this.titlesPage = 'refund.receiving-account-data'
      this.subTitlesPage = 'general.refund-information'
      return
    }

    if (this.physicalProduct && !this.refundTracking) {
      this.subTitlesPage = 'general.physical-product-information'
      return
    }
  },
  computed: {
    ...mapGetters(['getPurchaseData']),

    renderTitles() {
      return !['RefundRequestSent', 'NationalSent', 'NeedHelp'].includes(this.$route.name)
    },
    renderSubTitle() {
      return paymentMode.SIGNATURE === this.getPurchaseData.paymentMode ? 'general.subscription' : 'general.purchase'
    },
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700&display=swap');
@import '~@hotmart/cosmos/dist/styles/reset';
@import '~@hotmart/cosmos/dist/styles/base';
@import '~@hotmart/cosmos/dist/styles/utilities/display/display';
@import '~@hotmart/cosmos/dist/styles/utilities/flex/flex';
@import '~@hotmart/cosmos/dist/styles/utilities/spacing/spacing';
@import '~@hotmart/cosmos/dist/styles/utilities/text/text';
@import '~@hotmart/cosmos/dist/styles/utilities/text/variations/grays';
@import '~@hotmart/cosmos/dist/styles/utilities/sizing/sizing';
@import 'styles/refund-theme.scss';

.containerApp {
  max-width: var(--desktop-m);
  margin: var(--spacer-7) auto;
  padding-left: var(--spacer-4);
  padding-right: var(--spacer-4);
  h1 {
    color: var(--gray-500);
  }
  @media #{$medium-and-up} {
    h1 {
      color: var(--gray-700);
    }
  }
}
</style>
