import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import pkjson from '../../../package.json'
import router from '../../router'

const initSentry = () => {
  Sentry.init({
    Vue,
    dsn: 'https://41d4290d10cdef0a04d4117da7127f19@o49094.ingest.us.sentry.io/4506937699008512',
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 0.01,
    environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
    release: `app-${pkjson.name}@${pkjson.version}`,
    attachStacktrace: true,
    maxValueLength: 5000,
    ignoreErrors: [
      'Network Error',
      'Error: Network Error',
      'Network request failed',
      'Failed to fetch',
      'Load failed',
      'NetworkError',
      'Request failed with status code 500',
      'AbortError: The transaction was aborted, so the request cannot be fulfilled.',

      // Random plugins/extensions
      'top.GLOBALS',
      /* eslint-disable quotes */
      "Unexpected token '<'",
      'Unexpected token .',
      'Unexpected token )',
      'Syntax error',
      'Extension context invalidated.',
      'Maximum call stack size exceeded.',

      // ignoring requests without object
      'Non-Error promise rejection captured',

      // ignoring gtm.js errors
      'No error message',
      'elements.forEach is not a function',
      "Cannot read property 'innerText' of null",
      "Object doesn't support property or method 'forEach'",
      "Unable to get property 'innerText' of undefined or null reference",
      "null is not an object (evaluating 'element.innerText')",
      "Identifier 'selectors' has already been declared",
      'fbq is not defined',
      "Can't find variable: fbq",

      // Request aborted warnings in console from @hotmart/request
      'Request aborted',

      // Navigator share api canceled reject error
      'AbortError: Share canceled',
      'AbortError: Internal error: could not connect to Web Share interface.',
      'AbortError: Abort due to cancellation of share.',

      "null is not an object (evaluating 'a[w.checkoutSettingKeys.devToolsEnabled]')",
      /* eslint-enable quotes */
    ],
    denyUrls: [
      // Google
      /google.com/i,
      /gtm.js/i,

      // Facebook
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,

      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,

      // Firefox extensions
      /^resource:\/\//i,
      /^moz-extension:\/\//i,
    ],
  })
}

export { initSentry }
