import { axios } from '@hotmart/http'

const pendingRequest = new Map()

function getRequestKey(config) {
  const { method, url, params, data } = config
  return [method, url, JSON.stringify(params), JSON.stringify(data)].join('&')
}

axios.interceptors.request.use(
  config => {
    config.headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let requestKey = getRequestKey(config)

    if (pendingRequest.has(requestKey)) {
      let cancel = pendingRequest.get(requestKey)
      cancel()
      pendingRequest.delete(requestKey)
    }

    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken(cancel => {
        if (!pendingRequest.has(requestKey)) {
          pendingRequest.set(requestKey, cancel)
        }
      })
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

function delPendingRequest(config) {
  let requestKey = getRequestKey(config)
  if (pendingRequest.has(requestKey)) {
    pendingRequest.delete(requestKey)
  }
}

axios.interceptors.response.use(
  response => {
    delPendingRequest(response.config)
    return response
  },

  error => {
    if (!axios.isCancel(error)) {
      delPendingRequest(error.config || {})
    }
    if (!error) {
      error = {
        response: {
          data: { code: 'network_error' },
        },
      }
    } else if (!error.response) {
      error.response = { data: { code: 'network_error' } }
    } else if (!error.response.data) {
      error.response.data = { code: 'internal_server_error' }
    } else if (!error.response.data.code) {
      error.response.data.code = 'internal_server_error'
    }
    return Promise.reject(error)
  }
)
