export const paymentType = {
  CartaoDeCredito: {
    title: 'refund.check-product.card-information.payments.credit-card',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.next-invoice',
  },
  SaldoHotmart: {
    title: 'payment-method.HOTMART',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.five-working-days',
  },
  BoletoBancario: {
    title: 'refund.check-product.card-information.payments.bank-slip',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.seven-working-days',
  },
  DebitoBancario: {
    title: 'refund.check-product.card-information.payments.bank-debit',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.seven-working-days',
  },
  GOOGLE_PAY: {
    title: 'refund.check-product.card-information.payments.google-pay',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.thirty-to-sixty-days',
  },
  APPLE_PAY: {
    title: 'refund.check-product.card-information.payments.apple-pay',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.thirty-to-sixty-days',
  },
  MERCADO_PAGO: {
    title: 'refund.check-product.card-information.payments.mercado-pago',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.twenty-working-days',
  },
  NEQUI: {
    title: 'refund.check-product.card-information.payments.nequi',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.five-working-days',
  },
  SAMSUNG_PAY: {
    title: 'refund.check-product.card-information.payments.samsung-pay',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.thirty-to-sixty-days',
  },
  Paypal: {
    title: 'refund.check-product.card-information.payments.paypal',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.thirty-to-sixty-days',
  },
  PIX: {
    title: 'refund.check-product.card-information.payments.pix',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.seven-working-days',
  },
  CashPayment: {
    title: 'refund.check-product.card-information.payments.cash-payment',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.ten-working-days',
  },
  FINANCED_BILLET: {
    title: 'refund.check-product.card-information.payments.cash-payment',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.eight-working-days',
  },
  SEQURA: {
    title: 'refund.check-product.card-information.payments.sequra',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.next-invoice',
  },
  FINANCED_INSTALLMENT: {
    title: '',
    subtitle: '',
  },
  MACH_PAY: {
    title: 'refund.check-product.card-information.payments.mach-pay',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.five-working-days',
  },
  SERVIPAG: {
    title: 'refund.check-product.card-information.payments.servipag',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.five-working-days',
  },
  BANCOLOMBIA: {
    title: 'refund.check-product.card-information.payments.bancolombia',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.five-working-days',
  },
  MBWAY: {
    title: 'refund.check-product.card-information.payments.mbway',
    subtitle: 'refund.check-product.card-information.payments.feedbacks.seven-working-days',
  },
}

export const paymentTypes = {
  CREDIT_CARD: 'CartaoDeCredito',
  HOTMART_BALANCE: 'SaldoHotmart',
  BILLET: 'BoletoBancario',
  BANK_DEBIT: 'DebitoBancario',
  GOOGLE_PAY: 'GOOGLE_PAY',
  APPLE_PAY: 'APPLE_PAY',
  NEQUI: 'NEQUI',
  SAMSUNG_PAY: 'SAMSUNG_PAY',
  PAYPAL: 'Paypal',
  PIX: 'PIX',
  CASH_PAYMENT: 'CashPayment',
  FINANCED_BILLET: 'FINANCED_BILLET',
  HYBRID: 'HYBRID',
  SEQURA: 'SEQURA',
  MACH_PAY: 'MACH_PAY',
}

export const paymentMode = {
  SIGNATURE: 'ASSINATURA',
}
