import Vue from 'vue'
import VueRouter from 'vue-router'

const RefundRequest = () => import('@/views/RefundRequest/Base')
const CheckToken = () => import('@/views/RefundRequest/CheckToken')
const FindPurchaseCheckToken = () => import('@/views/RefundRequest/FindPurchaseCheckToken')
const CheckProduct = () => import('@/views/RefundRequest/CheckProduct')
const CheckEmail = () => import('@/views/RefundRequest/CheckEmail')
const Brazil = () => import('@/views/ReceivingAccounts/National')
const NationalSent = () => import('@/views/ReceivingAccounts/NationalSent')
const International = () => import('@/views/ReceivingAccounts/International')
const AccountRegistration = () => import('@/views/ReceivingAccounts/AccountRegistration')
const InternationalAccountRegistration = () => import('@/views/ReceivingAccounts/InternationalAccountRegistration')
const PurchaseList = () => import('@/views/RefundRequest/PurchaseList')
const RefundReason = () => import('@/views/RefundRequest/RefundReason')
const RefundFeedback = () => import('@/views/RefundRequest/RefundFeedback')
const RefundRequestSent = () => import('@/views/RefundRequest/RefundRequestSent')
const RefundTracking = () => import('@/views/RefundTracking/RefundTracking')
const TrackingCheckToken = () => import('@/views/RefundTracking/CheckToken')
const RefundTrackingInfo = () => import('@/views/RefundTracking/RefundTrackingInfo')
const NeedHelp = () => import('@/views/NeedHelp/Base')
const ProductAccess = () => import('@/views/ProductAccess/Base')

Vue.use(VueRouter)

const routes = [
  {
    name: 'RequestRefund',
    path: '',
    component: RefundRequest,
    props: false,
  },
  {
    name: 'CheckToken',
    path: '/security-code',
    component: CheckToken,
    props: false,
  },
  {
    name: 'CheckProduct',
    path: '/product',
    component: CheckProduct,
    props: false,
  },
  {
    name: 'CheckEmail',
    path: '/find-purchase/email',
    component: CheckEmail,
    props: false,
  },
  {
    name: 'PurchaseList',
    path: '/purchases',
    component: PurchaseList,
    props: false,
  },
  {
    name: 'FindPurchaseCheckToken',
    path: '/find-purchase/security-code',
    component: FindPurchaseCheckToken,
    props: false,
  },
  {
    name: 'AccountRegistration',
    path: '/bank-account-registration',
    component: AccountRegistration,
    props: true,
  },
  {
    name: 'InternationalAccountRegistration',
    path: '/international-bank-account-registration',
    component: InternationalAccountRegistration,
    props: true,
  },
  {
    name: 'Brazil',
    path: '/brazil/:transaction/:ucode',
    component: Brazil,
    props: true,
  },
  {
    name: 'International',
    path: '/international/:transaction/:ucode',
    component: International,
    props: true,
  },
  {
    name: 'RefundReason',
    path: '/refund-reason',
    component: RefundReason,
    props: true,
  },
  {
    name: 'RefundFeedback',
    path: '/refund-feedback',
    component: RefundFeedback,
    props: true,
  },
  {
    name: 'RefundRequestSent',
    path: '/refund-request-send',
    component: RefundRequestSent,
    props: true,
  },
  {
    name: 'NationalSent',
    path: '/account-registred',
    component: NationalSent,
    props: true,
  },
  {
    name: 'refundTracking',
    path: '/refund/tracking',
    component: RefundTracking,
    props: true,
  },
  {
    name: 'refundTracking',
    path: '/tracking',
    component: RefundTracking,
    props: true,
  },
  {
    name: 'refundTracking',
    path: '/tracking/security-code',
    component: TrackingCheckToken,
    props: false,
  },
  {
    name: 'refundTracking',
    path: '/tracking/details',
    component: RefundTrackingInfo,
    props: false,
  },
  {
    name: 'NeedHelp',
    path: '/need-help',
    component: NeedHelp,
    props: false,
  },
  {
    name: 'ProductAccess',
    path: '/product-access',
    component: ProductAccess,
    props: false,
  },
  {
    path: '*',
    component: RefundTracking, // TODO: create 404 page
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
