import './common/icons'
import './common/axios'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Toast from './plugins/Toast'
import VueGtm from '@gtm-support/vue2-gtm'
import { store } from './store/store'
import { initSentry } from './common/sentry'

Vue.config.ignoredElements = [/^hot-/, 'font-awesome-icon', 'router-link']

// Plugins
Vue.use(VueGtm, {
  id: 'GTM-K5D7V5L',
  enabled: ['production', 'staging'].includes(process.env.VUE_APP_ENV),
})

Vue.use(Toast, store)

if (process.env.NODE_ENV !== 'development') {
  initSentry()
}

new Vue({
  store: store,
  router,
  i18n,
  hotToast: Toast,
  render: h => h(App),
}).$mount('#app')
