import Vue from 'vue'
import VueI18n from 'vue-i18n'
import DE from './i18n/de.json'
import EN from './i18n/en.json'
import ES from './i18n/es.json'
import FR from './i18n/fr.json'
import IT from './i18n/it.json'
import JA from './i18n/ja.json'
import PT_BR from './i18n/pt-BR.json'

const locales = {
  de: DE,
  en: EN,
  es: ES,
  fr: FR,
  it: IT,
  ja: JA,
  'pt-BR': PT_BR,
}

const FALLBACK_LOCALE = 'en'

// it is possible to use more modern approaches, but it is no compatible with Safari and firefox
const getCookie = cookieName => {
  try {
    const cookieData = document.cookie.split(';').map(item => item.trim())
    const cookie = cookieData.find(row => row.startsWith(`${cookieName}=`))
    return cookie.split('=')[1]
  } catch {
    return ''
  }
}

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param) || ''
}

const getLocale = () => {
  const availableLanguages = Object.keys(locales).map(item => item.toLowerCase())
  const queryParamLanguage = getQueryParam('lang').toLowerCase()
  if (availableLanguages.find(item => item === queryParamLanguage)) {
    return queryParamLanguage
  }

  const cookieLanguage = getCookie('hmLangCookie').toLowerCase()

  if (availableLanguages.find(item => item === cookieLanguage)) {
    return cookieLanguage
  }

  const navigatorLanguage = navigator.language === 'pt' ? 'pt-br' : navigator.language.toLowerCase()
  if (availableLanguages.find(item => item === navigatorLanguage)) {
    return navigatorLanguage
  }

  return FALLBACK_LOCALE
}

const formatLocale = locale => {
  const langParts = locale.split(/[_-]/)
  let firstPartLocale = langParts[0].toLowerCase()
  if (langParts.length > 1) {
    firstPartLocale += `-${langParts[1].toUpperCase()}`
  }
  return firstPartLocale
}

Vue.use(VueI18n)

const initializeLocale = () => {
  try {
    return formatLocale(getLocale())
  } catch {
    return FALLBACK_LOCALE
  }
}

export default new VueI18n({
  locale: initializeLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: locales,
})
